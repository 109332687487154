var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Content',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentStep ==='settings'),expression:"currentStep ==='settings'"}],staticClass:"settings-wrapper"},[_c('Settings',{attrs:{"fields":[
          {
            name: 'player1',
            type: 'input',
            inputType: 'text',
            label: _vm.$c(_vm.$tc('ui.player')) + ' 1',
            initial: '',
            required: true
          },
          {
            name: 'vs',
            type: 'vs'
          },
          {
            name: 'player2',
            type: 'input',
            inputType: 'text',
            label: _vm.$c(_vm.$tc('ui.player')) + ' 2',
            initial: '',
            required: true
          },
          {
            name: 'linesNum',
            type: 'input',
            inputType: 'number',
            label: _vm.$t('ui.linesNum'),
            min: '4',
            initial: 6,
            required: true
          }
        ]},on:{"start-game":_vm.startGame}})],1),(_vm.currentStep ==='playing')?_c('Game1v1',{attrs:{"gameSettings":_vm.gameSettings},on:{"start-over":_vm.newGame}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }